import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import Navbar from './components/Navbar'

import Pform from './components/Pform';

function App() {
   
   
    return (
        <div>
            <Navbar></Navbar>

            <section className="p-0 position-relative mobile-height wow animate__fadeIn">
                <div className="opacity-extra-medium-2 bg-dark-gray border-radius-25px"></div>
               
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-12 p-0 full-screen d-flex flex-column justify-content-center text-center md-landscape-h-600px">
                        <Swiper
                                    spaceBetween={30}
                                    centeredSlides={true}
                                    autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    navigation={true}
                                    modules={[Autoplay, Pagination, Navigation]}
                                    className="mySwiper"
                                >
                                    <SwiperSlide><img src='img/s1.png' /></SwiperSlide>
                                    <SwiperSlide><img src='img/s2.png' /></SwiperSlide>
                                    <SwiperSlide><img src='img/s1.png' /></SwiperSlide>


                                </Swiper>
                               
                        </div>
                    </div>
                </div>
            </section>

            <section className="half-section bg-neon-blue">
                <div className="container">
                   
                        <div className="row justify-content-center d-flex align-items-center">
                       
                            <div className="col-12 col-md-6 wow animate__fadeIn" data-wow-delay="0.4s">
                            <h5 className="font-weight-600 text-fast-blue">OVERVIEW OF PROJECT</h5>
                                   
                                    <p className="text-fast-blue">Surya Valencia is a luxury <br />development with<br /> 122 Spanish Row Villas spread <br />over 9 pristine acres.</p>
                                    <p className="text-fast-blue"> 3258-3820 sft. Masterpieces</p>
                                    <p className="text-fast-blue"> G+2 & G+3 | 3.5 & 4.5 BHK</p>

                               

                            </div>
                            <div className="col-12 col-md-6 wow animate__fadeIn" data-wow-delay="0.4s">
                            <img className="w-100" src='img/s1.png' />

                            </div>
                           
                        </div>
                        <div className="row justify-content-center d-flex align-items-center mb-5 mt-5">
                       
                       <div className="col-12 col-md-6 wow animate__fadeIn" data-wow-delay="0.4s">
                       <h5 className="font-weight-600 text-fast-blue">PROJECT LOCATION</h5>
                              
                               <p className="text-fast-blue">Located in close proximity to multiple Tech parks, Aero park, ITIR, Reputed hospitals, International schools and top colleges.</p>

                          

                       </div>
                       <div className="col-12 col-md-6  wow animate__fadeIn" data-wow-delay="0.4s">
                       <div className="row">
                        <div className="col-6">
                       <img className="w-100" src='img/s1.png' />
                       </div>
                       <div className="col-6">
                       <img className="w-100" src='img/s1.png' />
                       </div>
                       </div></div>
                      
                   </div>
                   <div className="row justify-content-center d-flex align-items-center">
                       
                       <div className="col-12 col-md-6 wow animate__fadeIn" data-wow-delay="0.4s">
                       <h5 className="font-weight-600 text-fast-blue">AMENITIES</h5>
                              
                               <p className="text-fast-blue">Located in close proximity to multiple Tech parks, Aero park, ITIR, Reputed hospitals, International schools and top colleges.</p>

                          

                       </div>
                       <div className="col-12 col-md-6  wow animate__fadeIn" data-wow-delay="0.4s">
                       <img className="w-100" src='img/s2.png' />

                       </div>
                      
                   </div>
                   <div className="row mt-5">
                       
                       <div className="col-12 text-center wow animate__fadeIn" data-wow-delay="0.4s">
                     
                   <a href="clubplan" className="btn btn-fancy btn-small btn-fast-blue border-radius-20px">DOWNLOAD BROCHURE</a>
</div></div>
                </div>
            </section>


            <section className="half-section bg-light-pink">
                <div className="container">
                   
                        <div className="row justify-content-center d-flex align-items-center">
                       
                            <div className="col-12 col-md-6 wow animate__fadeIn" data-wow-delay="0.4s">
                            <h5 className="font-weight-600 text-fast-blue">NEARBY LANDMARKS</h5>
                            <h6 className="font-weight-600 text-pink">EDUCATIONAL INSTITUTIONS</h6>       
                                    <p className="text-black">Surya Valencia is a luxury <br />development with<br /> 122 Spanish Row Villas spread <br />over 9 pristine acres.</p>
                                    <p className="text-black"> 3258-3820 sft. Masterpieces</p>
                                    <p className="text-black"> G+2 & G+3 | 3.5 & 4.5 BHK</p>
                         <h6 className="font-weight-600 text-pink">HEALTHCARE</h6>       
                                    <p className="text-black">Surya Valencia is a luxury <br />development with<br /> 122 Spanish Row Villas spread <br />over 9 pristine acres.</p>
                                    <p className="text-black"> 3258-3820 sft. Masterpieces</p>
                                    <p className="text-black"> G+2 & G+3 | 3.5 & 4.5 BHK</p>
                         <h6 className="font-weight-600 text-pink">SHOPPING & ENTERTAINMENT</h6>       
                                    <p className="text-black">Surya Valencia is a luxury <br />development with<br /> 122 Spanish Row Villas spread <br />over 9 pristine acres.</p>
                                    <p className="text-black"> 3258-3820 sft. Masterpieces</p>
                                    <p className="text-black"> G+2 & G+3 | 3.5 & 4.5 BHK</p>  
                               

                            </div>
                            <div className="col-12 col-md-6 wow animate__fadeIn" data-wow-delay="0.4s">
                            <img className="w-100" src='img/s1.png' />

                            </div>
                           
                        </div>
                       
                  
                  
                </div>
            </section>

            <section className="half-section  bg-light-yellow">
                <div className="container">
                   
                        <div className="row justify-content-center d-flex align-items-center">
                       
                            <div className="col-12 col-md-6 wow animate__fadeIn" data-wow-delay="0.4s">
                            <h5 className="font-weight-600 text-fast-blue">VILLA DETAILS</h5>
                            <h6 className="font-weight-600 text-pink">4 BHK VILLA</h6>        
                                    <p className="text-fast-blue">Surya Valencia is a luxury <br />development with<br /> 122 Spanish Row Villas spread <br />over 9 pristine acres.</p>
                                    <p className="text-fast-blue"> 3258-3820 sft. Masterpieces</p>
                                    <p className="text-fast-blue"> G+2 & G+3 | 3.5 & 4.5 BHK</p>

                               

                            </div>
                            <div className="col-12 col-md-6 wow animate__fadeIn" data-wow-delay="0.4s">
                            <img className="w-100" src='img/s1.png' />

                            </div>
                           
                        </div>
                      
                   <div className="mt-5 row justify-content-center d-flex align-items-center">
                       
                       <div className="col-12 col-md-6 wow animate__fadeIn" data-wow-delay="0.4s">
                       <h6 className="font-weight-600 text-pink">5BHK VILLA</h6>  
                              
                               <p className="text-fast-blue">Located in close proximity to multiple Tech parks, Aero park, ITIR, Reputed hospitals, International schools and top colleges.</p>

                          

                       </div>
                       <div className="col-12 col-md-6  wow animate__fadeIn" data-wow-delay="0.4s">
                       <img className="w-100" src='img/s2.png' />

                       </div>
                      
                   </div>
                  
                </div>
            </section>
            <section className="p-0 position-relative mobile-height wow animate__fadeIn">
               
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-12 p-0 full-screen d-flex flex-column justify-content-center text-center md-landscape-h-600px">
                        <Swiper
                                    spaceBetween={30}
                                    centeredSlides={true}
                                    autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    navigation={true}
                                    modules={[Autoplay, Pagination, Navigation]}
                                    className="mySwiper"
                                >
                                    <SwiperSlide>        <div className="row justify-content-center">
                        <div className="col-12 col-md-10">
                            <div className="row justify-content-center">
                                <div className="col-md-12 text-center margin-seven-bottom">
                                    <h6 className="alt-font text-yellow-ochre font-weight-600">Host, work, and entertain</h6>
                                </div>
                            </div>
                            <div className="row row-cols-2 row-cols-md-7 justify-content-center">

                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i1.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Double Height Lobby</span>

                                </div>

                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i2.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Multipurpose Hall</span>

                                </div>

                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i3.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Pantry</span>

                                </div>

                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i4.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Co-working Space</span>

                                </div>

                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i5.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Café</span>

                                </div>
                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i6.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Creche</span>

                                </div>
                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i7.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Kids Play Area</span>

                                </div>
                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i8.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Convenience Store</span>

                                </div>
                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i9.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Conference Room</span>

                                </div>
                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i10.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Party Terrace</span>

                                </div>
                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i11.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Barbeque Counter</span>

                                </div>
                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i12.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Guest Rooms</span>

                                </div>
                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i13.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Multipurpose Court</span>

                                </div>

                            </div>
                        </div>
                    </div></SwiperSlide>
                                    <SwiperSlide> <div className="row justify-content-center">
                        <div className="col-12 col-md-8">
                            <div className="row justify-content-center">
                                <div className="col-md-12 text-center margin-seven-bottom">
                                    <h6 className="alt-font text-fast-blue font-weight-600">Staying active</h6>
                                </div>
                            </div>
                            <div className="row row-cols-2 row-cols-md-5 justify-content-center">

                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i19.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Gym</span>

                                </div>

                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i20.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Yoga Room</span>

                                </div>

                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i21.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Table Tennis Room</span>

                                </div>

                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i22.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Snooker/Billiards Room</span>

                                </div>

                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i23.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Indoor Games</span>

                                </div>
                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i24.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Badminton Court</span>

                                </div>
                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i25.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Outdoor Gym</span>

                                </div>
                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i26.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Play Lawn</span>

                                </div>
                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i27.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Golf Putting Zone</span>

                                </div>

                            </div>
                        </div>
                    </div></SwiperSlide>
                                    <SwiperSlide> <div className="row justify-content-center">
                        <div className="col-12 col-md-10">
                            <div className="row justify-content-center">
                                <div className="col-md-12 text-center margin-seven-bottom">
                                    <h6 className="alt-font text-white font-weight-600">Gardens & parks</h6>
                                </div>
                            </div>
                            <div className="row row-cols-2 row-cols-md-7 justify-content-center">

                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i32.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Butterfly Garden</span>

                                </div>

                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i33.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Meditation Garden</span>

                                </div>

                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i34.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Yoga / Activity Area</span>

                                </div>

                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i35.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Maze Walk</span>

                                </div>

                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i36.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Aroma Walk</span>

                                </div>
                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i37.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Congregation Lawn</span>

                                </div>
                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i38.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Focal Trellis</span>

                                </div>
                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i39.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Colanade Walk</span>

                                </div>
                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i40.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Floral Fountain</span>

                                </div>
                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i41.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Amphitheatre</span>

                                </div>
                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i42.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Open Party Area</span>

                                </div>
                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i43.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Trellis with Garden Sculpture</span>

                                </div>
                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i44.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Senior Seating Alcove</span>

                                </div>

                            </div>
                        </div>
                    </div></SwiperSlide>


                                </Swiper>
                               
                        </div>
                    </div>
                </div>
            </section>

            <section className="half-section bg-neon-blue">
                <div className="container">
                   
                        <div className="row justify-content-center d-flex align-items-center">
                       
                            <div className="col-12 col-md-6 wow animate__fadeIn" data-wow-delay="0.4s">
                            <h5 className="font-weight-600 text-fast-blue">ABOUT SURYA DEVELOPERS</h5>
                                   
                                    <p className="text-fast-blue">Surya Developers is a company founded on trust and expertise, with over 20 years of experience in real estate development and project administration.

Our team of professionals is dedicated to delivering exceptional service and ensuring timely completion of every project.</p>
                                    

                               

                            </div>
                             <div className="col-md-2"></div>
                            <div className="col-12 col-md-4 wow animate__fadeIn" data-wow-delay="0.4s">
                            <img className="w-100" src='img/s1.png' />
                            <div className="row mt-4 mb-4">
                        <div className="col-6">
                       <img className="w-100" src='img/s1.png' />
                       </div>
                       <div className="col-6">
                       <img className="w-100" src='img/s1.png' />
                       </div>
                       </div>
                       <img className="w-100" src='img/s2.png' />
                            </div>
                           
                        </div>
                       
                   
                  
                </div>
            </section>


            <footer className="footer-dark bg-black">

                <div className="footer-bottom padding-40px-tb border-top border-color-white-transparent">
                    <div className="container">
                        <div className="row align-items-center justify-content-center d-flex">
                           
                            <div className="col-6 col-md-2 text-center sm-margin-20px-bottom">
                                <a href="index.html" className="footer-logo"><img src="img/logo.png" alt="" /></a>
                            </div>
                            <div className="col-6 col-md-2 text-center sm-margin-20px-bottom">
                                <a href="index.html" className="footer-logo"><img src="img/footer-logo.svg" alt="" /></a>
                            </div>
                            
                            <div className="col-6 col-md-3 text-center sm-margin-20px-bottom">
                                <p className="text-small"> <a classname="text-white" href="#">PRIVACY POLICY</a> </p>
                            </div>
                            <div className="col-6 col-md-3 text-center sm-margin-20px-bottom">
                                <p className="text-small"> <a classname="text-white" href="#">TC's</a> </p>
                            </div>
                            <div className="col-12 col-md-2 text-center sm-margin-20px-bottom">
                                <p className="text-white font-weight-600 text-small">Copyright2025Surya</p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>



        </div>
    );
}

export default App;
