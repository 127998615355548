import React from 'react'
import Navbar from '../components/Navbar'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';


function Demo() {
   
   
    return (
        <div>
            <Navbar></Navbar>

            <section className="p-0 position-relative mobile-height wow animate__fadeIn">
                <div className="opacity-extra-medium-2 bg-dark-gray border-radius-25px"></div>
               
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-12 p-0 full-screen d-flex flex-column justify-content-center text-center md-landscape-h-600px">
                        <Swiper
                                    spaceBetween={30}
                                    centeredSlides={true}
                                    autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    navigation={true}
                                    modules={[Autoplay, Pagination, Navigation]}
                                    className="mySwiper"
                                >
                                    <SwiperSlide><img src='img/s1.png' /></SwiperSlide>
                                    <SwiperSlide><img src='img/s2.png' /></SwiperSlide>
                                    <SwiperSlide><img src='img/s1.png' /></SwiperSlide>


                                </Swiper>
                               
                        </div>
                    </div>
                </div>
            </section>

            <section className="half-section bg-neon-blue">
                <div className="container">
                   
                        <div className="row justify-content-center d-flex align-items-center">
                       
                            <div className="col-12 col-md-6 wow animate__fadeIn" data-wow-delay="0.4s">
                            <h5 className="font-weight-600 text-fast-blue">OVERVIEW OF PROJECT</h5>
                                   
                                    <p className="text-fast-blue">Surya Valencia is a luxury <br />development with<br /> 122 Spanish Row Villas spread <br />over 9 pristine acres.</p>
                                    <p className="text-fast-blue"> 3258-3820 sft. Masterpieces</p>
                                    <p className="text-fast-blue"> G+2 & G+3 | 3.5 & 4.5 BHK</p>

                               

                            </div>
                            <div className="col-12 col-md-6 wow animate__fadeIn" data-wow-delay="0.4s">
                            <img className="w-100" src='img/s1.png' />

                            </div>
                           
                        </div>
                        <div className="row justify-content-center d-flex align-items-center mb-5 mt-5">
                       
                       <div className="col-12 col-md-6 wow animate__fadeIn" data-wow-delay="0.4s">
                       <h5 className="font-weight-600 text-fast-blue">PROJECT LOCATION</h5>
                              
                               <p className="text-fast-blue">Located in close proximity to multiple Tech parks, Aero park, ITIR, Reputed hospitals, International schools and top colleges.</p>

                          

                       </div>
                       <div className="col-12 col-md-6  wow animate__fadeIn" data-wow-delay="0.4s">
                       <div className="row">
                        <div className="col-6">
                       <img className="w-100" src='img/s1.png' />
                       </div>
                       <div className="col-6">
                       <img className="w-100" src='img/s1.png' />
                       </div>
                       </div></div>
                      
                   </div>
                   <div className="row justify-content-center d-flex align-items-center">
                       
                       <div className="col-12 col-md-6 wow animate__fadeIn" data-wow-delay="0.4s">
                       <h5 className="font-weight-600 text-fast-blue">AMENITIES</h5>
                              
                               <p className="text-fast-blue">Located in close proximity to multiple Tech parks, Aero park, ITIR, Reputed hospitals, International schools and top colleges.</p>

                          

                       </div>
                       <div className="col-12 col-md-6  wow animate__fadeIn" data-wow-delay="0.4s">
                       <img className="w-100" src='img/s2.png' />

                       </div>
                      
                   </div>
                   <div className="row mt-5">
                       
                       <div className="col-12 text-center wow animate__fadeIn" data-wow-delay="0.4s">
                     
                   <a href="clubplan" className="btn btn-fancy btn-small btn-fast-blue border-radius-20px">DOWNLOAD BROCHURE</a>
</div></div>
                </div>
            </section>


            <section className="half-section bg-light-pink">
                <div className="container">
                   
                        <div className="row justify-content-center d-flex align-items-center">
                       
                            <div className="col-12 col-md-6 wow animate__fadeIn" data-wow-delay="0.4s">
                            <h5 className="font-weight-600 text-fast-blue">NEARBY LANDMARKS</h5>
                            <h6 className="font-weight-600 text-pink">EDUCATIONAL INSTITUTIONS</h6>       
                                    <p className="text-black">Surya Valencia is a luxury <br />development with<br /> 122 Spanish Row Villas spread <br />over 9 pristine acres.</p>
                                    <p className="text-black"> 3258-3820 sft. Masterpieces</p>
                                    <p className="text-black"> G+2 & G+3 | 3.5 & 4.5 BHK</p>
                         <h6 className="font-weight-600 text-pink">HEALTHCARE</h6>       
                                    <p className="text-black">Surya Valencia is a luxury <br />development with<br /> 122 Spanish Row Villas spread <br />over 9 pristine acres.</p>
                                    <p className="text-black"> 3258-3820 sft. Masterpieces</p>
                                    <p className="text-black"> G+2 & G+3 | 3.5 & 4.5 BHK</p>
                         <h6 className="font-weight-600 text-pink">SHOPPING & ENTERTAINMENT</h6>       
                                    <p className="text-black">Surya Valencia is a luxury <br />development with<br /> 122 Spanish Row Villas spread <br />over 9 pristine acres.</p>
                                    <p className="text-black"> 3258-3820 sft. Masterpieces</p>
                                    <p className="text-black"> G+2 & G+3 | 3.5 & 4.5 BHK</p>  
                               

                            </div>
                            <div className="col-12 col-md-6 wow animate__fadeIn" data-wow-delay="0.4s">
                            <img className="w-100" src='img/s1.png' />

                            </div>
                           
                        </div>
                       
                  
                  
                </div>
            </section>

            <section className="half-section ">
                <div className="container">
                   <div classname="row"><div classname="col-12">
                   <h5 className="font-weight-600 text-fast-blue mt-5">VILLA DETAILS</h5>
                    </div></div>
                    <Swiper
                                    spaceBetween={30}
                                    centeredSlides={true}
                                    autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    navigation={true}
                                    modules={[Autoplay, Pagination, Navigation]}
                                    className="mySwiper"
                                >
                                    <SwiperSlide>  
                                        <div className="row justify-content-center d-flex align-items-center">
                       
                       <div className="col-12 col-md-6 wow animate__fadeIn text-left" data-wow-delay="0.4s">
                       
                       <h6 className="font-weight-600 text-pink">4 BHK VILLA</h6>        
                               <p className="text-fast-blue">Surya Valencia is a luxury <br />development with<br /> 122 Spanish Row Villas spread <br />over 9 pristine acres.</p>
                               <p className="text-fast-blue"> 3258-3820 sft. Masterpieces</p>
                               <p className="text-fast-blue"> G+2 & G+3 | 3.5 & 4.5 BHK</p>

                          

                       </div>
                       <div className="col-12 col-md-6 wow animate__fadeIn" data-wow-delay="0.4s">
                       <img className="w-100" src='img/s1.png' />

                       </div>
                      
                   </div>
                 </SwiperSlide>
                <SwiperSlide>        <div className="row justify-content-center d-flex align-items-center">
                       
                       <div className="col-12 col-md-6 wow animate__fadeIn text-left" data-wow-delay="0.4s">
                       <h6 className="font-weight-600 text-pink">5BHK VILLA</h6>  
                              
                               <p className="text-fast-blue">Located in close proximity to multiple Tech parks, Aero park, ITIR, Reputed hospitals, International schools and top colleges.</p>

                          

                       </div>
                       <div className="col-12 col-md-6  wow animate__fadeIn" data-wow-delay="0.4s">
                       <img className="w-100" src='img/s2.png' />

                       </div>
                      
                   </div></SwiperSlide>


                                </Swiper>
                      
           
                  
                </div>
            </section>
            <section className="half-section">
                <div className="container">
                    <div className="row margin-5-half-rem-bottom align-items-center">
                        <div className="col-12 text-center wow animate__fadeIn" data-wow-delay="0.1s">
                            <h5 className="font-weight-600 text-fast-blue letter-spacing-1px ">Gallery</h5>

                        </div>

                    </div>
                    <div className="row">
                        <div className="col-12 p-0 wow animate__fadeIn text-center" data-wow-delay="0.4s">
                            <Swiper
                                slidesPerView={1}
                                spaceBetween={10}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                                pagination={{
                                    clickable: true,
                                }}
                                breakpoints={{
                                    640: {
                                        slidesPerView: 2,
                                        spaceBetween: 20,
                                    },
                                    768: {
                                        slidesPerView: 3,
                                        spaceBetween: 40,
                                    },
                                    1024: {
                                        slidesPerView: 3,
                                        spaceBetween: 50,
                                    },
                                }}
                                modules={[Autoplay, Pagination, Navigation]}
                                className="mySwiper"
                            >
                                <SwiperSlide><img src='img/g1.png' /><h6 className="text-black">Lorem Ipsum Dolor</h6></SwiperSlide>
                                <SwiperSlide><img src='img/g2.png' /><h6 className="text-black">Lorem Ipsum Dolor</h6></SwiperSlide>
                                <SwiperSlide><img src='img/g3.png' /><h6 className="text-black">Lorem Ipsum Dolor</h6></SwiperSlide>
                                <SwiperSlide><img src='img/g4.png' /><h6 className="text-black">Lorem Ipsum Dolor</h6></SwiperSlide>

                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
            <section className="half-section wow animate__fadeIn">
               
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-12 d-flex flex-column justify-content-center text-center">
                        <Swiper
                                    spaceBetween={30}
                                    centeredSlides={true}
                                    autoplay={{
                                        delay: 6000,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    navigation={true}
                                    modules={[Autoplay, Pagination, Navigation]}
                                    className="mySwiper"
                                >
                                    <SwiperSlide>        
                                        <div className="row justify-content-center">
                        <div className="col-12 col-md-8">
                            <div className="row justify-content-center">
                                <div className="col-md-12 text-center margin-seven-bottom">
                                    <h6 className="alt-font text-yellow-ochre font-weight-600">Host, work, and entertain</h6>
                                </div>
                            </div>
                            <div className="row row-cols-2 row-cols-md-7 justify-content-center">

                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i1.png" className="imgx padding-20px-bottom" />
                                    <span className="text-yellow-ochre d-block">Double Height Lobby</span>

                                </div>

                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i2.png" className="imgx padding-20px-bottom" />
                                    <span className="text-yellow-ochre d-block">Multipurpose Hall</span>

                                </div>

                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i3.png" className="imgx padding-20px-bottom" />
                                    <span className="text-yellow-ochre d-block">Pantry</span>

                                </div>

                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i4.png" className="imgx padding-20px-bottom" />
                                    <span className="text-yellow-ochre d-block">Co-working Space</span>

                                </div>

                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i5.png" className="imgx padding-20px-bottom" />
                                    <span className="text-yellow-ochre d-block">Café</span>

                                </div>
                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i6.png" className="imgx padding-20px-bottom" />
                                    <span className="text-yellow-ochre d-block">Creche</span>

                                </div>
                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i7.png" className="imgx padding-20px-bottom" />
                                    <span className="text-yellow-ochre d-block">Kids Play Area</span>

                                </div>
                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i8.png" className="imgx padding-20px-bottom" />
                                    <span className="text-yellow-ochre d-block">Convenience Store</span>

                                </div>
                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i9.png" className="imgx padding-20px-bottom" />
                                    <span className="text-yellow-ochre d-block">Conference Room</span>

                                </div>
                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i10.png" className="imgx padding-20px-bottom" />
                                    <span className="text-yellow-ochre d-block">Party Terrace</span>

                                </div>
                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i11.png" className="imgx padding-20px-bottom" />
                                    <span className="text-yellow-ochre d-block">Barbeque Counter</span>

                                </div>
                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i12.png" className="imgx padding-20px-bottom" />
                                    <span className="text-yellow-ochre d-block">Guest Rooms</span>

                                </div>
                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i13.png" className="imgx padding-20px-bottom" />
                                    <span className="text-yellow-ochre d-block">Multipurpose Court</span>

                                </div>

                            </div>
                        </div>
                    </div></SwiperSlide>
                                    <SwiperSlide> <div className="row justify-content-center">
                        <div className="col-12 col-md-6">
                            <div className="row justify-content-center">
                                <div className="col-md-12 text-center margin-seven-bottom">
                                    <h6 className="alt-font text-fast-blue font-weight-600">Staying active</h6>
                                </div>
                            </div>
                            <div className="row row-cols-2 row-cols-md-5 justify-content-center">

                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i19.png" className="imgx padding-20px-bottom" />
                                    <span className="text-fast-blue d-block">Gym</span>

                                </div>

                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i20.png" className="imgx padding-20px-bottom" />
                                    <span className="text-fast-blue d-block">Yoga Room</span>

                                </div>

                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i21.png" className="imgx padding-20px-bottom" />
                                    <span className="text-fast-blue d-block">Table Tennis Room</span>

                                </div>

                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i22.png" className="imgx padding-20px-bottom" />
                                    <span className="text-fast-blue d-block">Snooker/Billiards Room</span>

                                </div>

                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i23.png" className="imgx padding-20px-bottom" />
                                    <span className="text-fast-blue d-block">Indoor Games</span>

                                </div>
                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i24.png" className="imgx padding-20px-bottom" />
                                    <span className="text-fast-blue d-block">Badminton Court</span>

                                </div>
                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i25.png" className="imgx padding-20px-bottom" />
                                    <span className="text-fast-blue d-block">Outdoor Gym</span>

                                </div>
                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i26.png" className="imgx padding-20px-bottom" />
                                    <span className="text-fast-blue d-block">Play Lawn</span>

                                </div>
                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i27.png" className="imgx padding-20px-bottom" />
                                    <span className="text-fast-blue d-block">Golf Putting Zone</span>

                                </div>

                            </div>
                        </div>
                    </div></SwiperSlide>
                     


                                </Swiper>
                               
                        </div>
                    </div>
                </div>
            </section>

            <section className="half-section bg-neon-blue">
                <div className="container">
                   
                        <div className="row justify-content-center d-flex align-items-center">
                       
                            <div className="col-12 col-md-6 wow animate__fadeIn" data-wow-delay="0.4s">
                            <h5 className="font-weight-600 text-fast-blue">ABOUT SURYA DEVELOPERS</h5>
                                   
                                    <p className="text-fast-blue">Surya Developers is a company founded on trust and expertise, with over 20 years of experience in real estate development and project administration.

Our team of professionals is dedicated to delivering exceptional service and ensuring timely completion of every project.</p>
                                    

                               

                            </div>
                             <div className="col-md-2"></div>
                            <div className="col-12 col-md-4 wow animate__fadeIn" data-wow-delay="0.4s">
                            <img className="w-100" src='img/s1.png' />
                            <div className="row mt-4 mb-4">
                        <div className="col-6">
                       <img className="w-100" src='img/s1.png' />
                       </div>
                       <div className="col-6">
                       <img className="w-100" src='img/s1.png' />
                       </div>
                       </div>
                       <img className="w-100" src='img/s2.png' />
                            </div>
                           
                        </div>
                       
                   
                  
                </div>
            </section>
            <section> 
                <div className="container">
                   
                   <div className="row justify-content-center d-flex align-items-center">
                   <div className="col-md-6">
                    <h4 className="text-black">CONTACT US</h4>
                    <p>No.684,2nd floor, HIG 'A' Sector,Opposite to Sheshadripuram College,Above State Bank of Hyderabad, Yelahanka NewTown, Bangalore - 560064.

</p><p>E-Mail : info@suryadevelopers.in

</p><p>Mob No : +91 7411118484

</p>
                        <ul class="small-icon">
                            <li><a className="facebook text-extra-dark-gray text-sm-start" href="https://www.facebook.com/" target="_blank"><i class="fa-brands fa-facebook-f"></i></a></li>
                            <li><a className="twitter text-extra-dark-gray text-sm-start" href="http://www.twitter.com" target="_blank"><i class="fa-brands fa-twitter"></i></a></li>
                            <li><a className="instagram text-extra-dark-gray text-sm-start" href="http://www.instagram.com" target="_blank"><i class="fa-brands fa-instagram"></i></a></li>
                            <li><a className="linkedin text-extra-dark-gray text-sm-start" href="http://www.linkedin.com" target="_blank"><i class="fa-brands fa-linkedin-in"></i></a></li>

                        </ul>
                    
                   </div>
                    <div className="col-md-6"><iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d62176.01522699359!2d77.587559!3d13.099126!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae185e57a7144f%3A0xd8365d4f23d3c88a!2sSurya%20Developers!5e0!3m2!1sen!2sin!4v1710335546573!5m2!1sen!2sin" class="map-frame" allowfullscreen="" width="100%" height="560"></iframe>
            </div>
            </div></div></section>

            <footer className="footer-dark bg-black">

                <div className="footer-bottom padding-40px-tb border-top border-color-white-transparent">
                    <div className="container">
                        <div className="row align-items-center justify-content-center d-flex">
                           
                            <div className="col-6 col-md-2 text-center sm-margin-20px-bottom">
                                <a href="index.html" className="footer-logo"><img src="img/logo.png" alt="" /></a>
                            </div>
                            <div className="col-6 col-md-2 text-center sm-margin-20px-bottom">
                                <a href="index.html" className="footer-logo"><img src="img/footer-logo.svg" alt="" /></a>
                            </div>
                            
                            <div className="col-6 col-md-3 text-center sm-margin-20px-bottom">
                                <p className="text-small"> <a classname="text-white" href="#">PRIVACY POLICY</a> </p>
                            </div>
                            <div className="col-6 col-md-3 text-center sm-margin-20px-bottom">
                                <p className="text-small"> <a classname="text-white" href="#">TC's</a> </p>
                            </div>
                            <div className="col-12 col-md-2 text-center sm-margin-20px-bottom">
                                <p className="text-white font-weight-600 text-small">Copyright2025Surya</p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>



        </div>
    );
}

export default Demo;
